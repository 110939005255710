/* eslint-disable no-redeclare */
import React, { Component } from 'react';
// import Title from './PageTitle.jsx';
import Dropzone from 'react-dropzone';
import EnvironmentConfiguration from '../config';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

// import git from "nodegit"
// reactstrap components
import {
  Container,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Card,
  CardTitle,
  Spinner,
  Input,
  Alert
} from 'reactstrap';

const AWS = require('aws-sdk');
var s3 = new AWS.S3();
var lambda = new AWS.Lambda();
var Auth = null;
var config = EnvironmentConfiguration.getConfig();
var JSZip = require('jszip');

export default class Upload extends Component {
  constructor(props) {
    super(props);

    Auth = this.props.auth;

    this.state = {
      isAuthenticated: false,
      files: new Array(0),
      committing: false,
      courses: this.props.courses,
      course: null,
      newCourse: false,
      dropdownOpen: false,
      loading: false,
      commitMessage: '',
      canUpload: this.props.canUpload,
      doneCommitting: false,
      errorCommitting: false,
      errorMessage: ''
    };
  }

  componentDidMount() {
    this.setState({ isAuthenticated: this.props.isAuthenticated });

    Auth.currentCredentials().then((credentials) => {
      s3 = new AWS.S3({ credentials: credentials });
      lambda = new AWS.Lambda({
        credentials: credentials,
        region: 'us-east-1'
      });
    });
  }

  toast = (message) => {
    toast(message, {
      hideProgressBar: true,
      className: 'success-toast',
      bodyClassName: 'toast-body'
    });
  };

  readFile = async (file, resolve) => {
    if (!file) {
      return;
    }
    var reader = new FileReader();
    reader.onloadend = (e) => {
      this.forceUpdate();
      resolve(file, e.target.result, this);
    };
    await reader.readAsArrayBuffer(file);
  };

  readFiles = async (files) => {
    var contentList = [];
    this.setState({ loading: true });

    for (var i = 0; i < files.length; i++) {
      await this.readFile(files[i], (readFile, content, context) => {
        contentList.push({
          path: readFile.path,
          content: content,
          type: readFile.type
        });
        if (contentList.length === files.length) {
          context.setState({ loading: false });
        }
      });
    }
    return contentList;
  };

  uploadZips = async (files = null) => {
    if (!files) {
      files = this.state.files;
    }
    for (var i = 0; i < files.length; i++) {
      s3.upload(
        {
          Bucket: config.s3.WEBAPP,
          Key: 'uploads/' + files[i].path,
          Body: files[i].content
        },
        { partSize: 5 * 1024 * 1024 },
        (err) => {
          if (err) {
            this.setState({ errorCommitting: true, errorMessage: err.stack });
          }
        }
      );
    }
  };

  createPathInZip = (zip, path, content) => {
    var pathArray = path.split('/');
    for (var i = 1; i <= pathArray.length; i++) {
      if (i === pathArray.length) {
        zip.file(path, content);
        return;
      }

      zip.folder(pathArray.slice(0, i).join('/'));
    }
  };

  zipFiles = async () => {
    var filesData = this.state.files;
    var projects = [];
    var courseRoot = [];
    var fileContent = [];
    var zips = [];
    for (var i = 0; i < filesData.length; i++) {
      var file = filesData[i];
      if (file.path.split('/').length <= 1) {
        courseRoot.push(file);
        continue;
      }

      var projectName = file.path.split('/')[1];
      if (!projects.includes(projectName)) {
        projects.push(projectName);
      }
    }

    for (var i = 0; i < courseRoot.length; i++) {
      fileContent.push({
        path: courseRoot[i].path,
        content: courseRoot[i].content
      });
    }

    for (var n = 0; n < projects.length; n++) {
      var projectName = projects[n];
      var zip = new JSZip();
      zips.push(zip);
      for (var i = 0; i < filesData.length; i++) {
        var file = filesData[i];
        if (file.path.split('/')[1] !== projectName) {
          continue;
        }

        var zipPath = file.path.split('/').slice(2).join('/');

        this.createPathInZip(zip, zipPath, file.content);
      }
      await zip.generateAsync({ type: 'arraybuffer' }).then((content) => {
        fileContent.push({
          path: projectName + '.zip',
          content: content
        });
      });
    }

    return fileContent;
  };

  commit = async () => {
    this.setState({
      committing: true,
      doneCommitting: false,
      errorCommitting: false,
      errorMessage: ''
    });
    var allZip = true;
    for (var i = 0; i < this.state.files.length; i++) {
      if (this.state.files[i].type !== 'application/x-zip-compressed') {
        allZip = false;
        break;
      }
    }

    if (!allZip) {
      this.uploadZips(await this.zipFiles());
    } else {
      await this.uploadZips();
    }

    var defaultMessage = 'Adding to course ' + this.state.course;

    var functionPayload = {
      bucket: config.s3.WEBAPP,
      repository: config.codeCommit.REPOSITORY_NAME,
      course: this.state.course,
      roleArn: config.codeCommit.ROLE_ARN,
      commitMessage: this.state.commitMessage == '' ? defaultMessage : this.state.commitMessage
    };

    lambda.invoke(
      {
        FunctionName: 'arn:aws:lambda:us-east-1:719721023010:function:cipc-remote-commit-lambda',
        Payload: JSON.stringify(functionPayload)
      },
      (err, data) => {
        if (err) {
          this.setState({ errorCommitting: true, errorMessage: err.stack });
        } else if (data) {
          this.setState({ doneCommitting: true });
        }
      }
    );

    this.setState({ committing: false });
  };

  handleDrop = async (acceptedFiles) => {
    this.setState({
      doneCommitting: false,
      errorCommitting: false,
      errorMessage: ''
    });
    if (this.state.course === null) {
      return;
    }
    var files = await this.readFiles(acceptedFiles);
    this.setState({ files: files });
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  };

  selectCourse = (courseName) => {
    courseName = courseName.replace('/', '').replace('\\', '');
    this.setState({ course: courseName, newCourse: false, files: [] });
  };

  selectNewCourseName = (courseName) => {
    if (courseName === '') {
      this.setState({ courseName: null, files: [] });
      return;
    }
    courseName = courseName.replace('/', '').replace('\\', '');
    this.setState({ course: courseName, newCourse: true, files: [] });
  };

  newCourse = () => {
    this.setState({ course: null, newCourse: true });
  };

  validCommit = () => {
    if (!this.state.files) {
      return false;
    }

    if (this.state.files.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  renderUploadedCourses = () => {
    if (!this.state.uploadedCourses) {
      return;
    }
    var uploadedCourses = [];
    this.state.uploadedCourses.forEach((course) => {
      uploadedCourses.push(
        <Card
          key={course.name + course.part + '/' + course.totalParts + '-commit-card'}
          style={{ flexShrink: '0' }}>
          <CardTitle>{course.name}</CardTitle>
          <p>File amount: {course.amount}</p>
          <p>
            Commit part {course.part}/{course.totalParts}
          </p>
        </Card>
      );
    });

    return (
      <div
        style={{
          width: '',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          justifyContent: 'flexStart',
          overflowX: 'auto'
        }}>
        {uploadedCourses}
      </div>
    );
  };

  renderDropdown = () => {
    var dropdownItems = [];
    dropdownItems.push(
      <DropdownItem
        key="selectCourse"
        onClick={() => {
          this.setState({ course: null, newCourse: null });
        }}>
        Select Course
      </DropdownItem>
    );
    this.props.courses.forEach((course) => {
      dropdownItems.push(
        <DropdownItem key={course} onClick={() => this.selectCourse(course)}>
          {course}
        </DropdownItem>
      );
    });

    dropdownItems.push(
      <DropdownItem key="newCourse" onClick={this.newCourse}>
        Create New Course
      </DropdownItem>
    );

    return (
      <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
        <DropdownToggle caret>
          {this.state.course === null || this.state.newCourse ? 'Select Course' : this.state.course}
        </DropdownToggle>
        <DropdownMenu>{dropdownItems}</DropdownMenu>
      </Dropdown>
    );
  };

  render() {
    if (!this.props.isAuthenticated) {
      this.props.history.push({
        pathname: '/',
        state: {
          isAuthenticated: this.props.isAuthenticated,
          courses: this.props.courses
        }
      });
    }

    return (
      <React.Fragment>
        <Container id="upload-modal">
          <h1>
            Upload file to the <span className="highlight">CIPC File Repository</span>
          </h1>
          <Row>
            <Col>{this.renderDropdown()}</Col>
            {this.state.newCourse ? (
              <Col>
                <Input
                  id="newCourseInput"
                  placeholder="Name of new course"
                  onChange={(courseName) => {
                    this.selectNewCourseName(courseName.target.value);
                  }}></Input>
              </Col>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </Row>
          {this.state.course !== null ? (
            <React.Fragment>
              <Dropzone
                onDrop={(acceptedFiles, regectedFiles) =>
                  this.handleDrop(acceptedFiles, regectedFiles)
                }>
                {({ getRootProps, getInputProps }) => (
                  <section className="dropzone">
                    <div id="outer" {...getRootProps()}>
                      <input {...getInputProps()} />
                      {this.validCommit() ? (
                        <React.Fragment />
                      ) : (
                        <Button>Click to add files!</Button>
                      )}
                      <Container
                        className="fileList"
                        style={this.validCommit() ? {} : { display: 'none' }}>
                        {this.state.files !== undefined ? (
                          this.state.files.map((file) => (
                            <Row key={file.path}>
                              <Col style={{ textAlign: 'left' }}>
                                <p>{file.path}</p>
                              </Col>
                            </Row>
                          ))
                        ) : (
                          <React.Fragment />
                        )}
                      </Container>
                    </div>
                  </section>
                )}
              </Dropzone>
            </React.Fragment>
          ) : (
            <React.Fragment></React.Fragment>
          )}
          {this.state.errorCommitting ? (
            <Alert color="danger">
              <b>ERROR: </b>
              {this.state.errorMessage}
            </Alert>
          ) : this.state.doneCommitting ? (
            <Alert color="success">Uploaded changes for commit.</Alert>
          ) : !this.state.course ? (
            <React.Fragment />
          ) : this.state.loading ? (
            <Row>
              <Col>
                <Input
                  id="commitMessage"
                  placeholder="brief commit message"
                  onChange={(message) =>
                    this.setState({ commitMessage: message.target.value })
                  }></Input>
              </Col>
              <Col>
                <Button color="secondary" block disabled>
                  <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />{' '}
                  Loading Files...
                </Button>
              </Col>
            </Row>
          ) : this.validCommit() ? (
            <Row>
              <Col>
                <Input
                  id="commitMessage"
                  placeholder="brief commit message"
                  onChange={(message) =>
                    this.setState({ commitMessage: message.target.value })
                  }></Input>
              </Col>
              {!this.state.committing ? (
                <Col>
                  <Button color="primary" block onClick={this.commit}>
                    Commit Files
                  </Button>
                </Col>
              ) : (
                <Col>
                  <Button color="secondary" block disabled>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />{' '}
                    Committing your files...
                  </Button>
                </Col>
              )}
            </Row>
          ) : (
            <Row>
              <Col>
                <Input
                  id="commitMessage"
                  placeholder="brief commit message"
                  onChange={(message) =>
                    this.setState({ commitMessage: message.target.value })
                  }></Input>
              </Col>
              <Col>
                <Button color="danger" block disabled>
                  No Files Added!
                </Button>
              </Col>
            </Row>
          )}
          {this.renderUploadedCourses()}
        </Container>
      </React.Fragment>
    );
  }
}

Upload.propTypes = {
  auth: PropTypes.object,
  courses: PropTypes.object,
  canUpload: PropTypes.bool,
  isAuthenticated: PropTypes.bool,
  history: PropTypes.object
};
